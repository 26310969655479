import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import useResize from '../../utils/hooks/useResize';
import {
  clearOffers,
  fetchAutoSuggestText,
  searchPlaceUser,
  setHousingOffers,
  setSearchData,
} from '../../store/market/actions';
import { IMMOBILIE, MARKTMIETSPIEGEL } from '../../utils/constants';
import CustomToast from '../common/Toast';
import SearchBoxSection from './Sections/SearchBox';
import AboutText from './Sections/aboutText2';
import CityMietspiegelSection from './Sections/cityMietspiegel3';
import FindImmoSection from './Sections/findImmo4';
import OfferCarouselSection from './Sections/offerCarousel5';
import RentBuyCityListSection from './Sections/rentBuyCityList6';
import Footer from './Sections/footer7';

const Home = ({ resultData, options }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const isGeolocationAsked = useRef(false);

  const [isActive, setIsActive] = useState(IMMOBILIE);
  const [isGeoSuccess, setGeoSuccess] = useState(false);
  const [geoUserData, setUserGeoData] = useState({});
  const [place, setPlace] = useState('');
  const [housing, setHousing] = useState('wohnung');
  const [rentOrBuy, setRentOrBuy] = useState('mieten');
  const [realEstateOrMarket, setRealEstateOrMarket] = useState(IMMOBILIE);
  const [autoSuggestPlaces, setSuggestPlaces] = useState([]);
  const [isChoosePlace, setChoosePlace] = useState(false);
  const [selectedChoosePlace, setSelectedChoosePlace] = useState({});
  const [isShowToast, setShowToast] = useState(false);
  const screenWidth = useResize();

  const [selectItem, setSelectItem] = useState();

  const { propertyAllListRent, parentData } = resultData;

  const search = useCallback(
    async (lat, lng) => {
      const response = await dispatch(
        searchPlaceUser({
          lon: lng,
          lat,
        }),
      );
      const data = response?.data;
      if (data && data?.items?.length > 0) {
        setUserGeoData(data);
        setGeoSuccess(true);
      }
      if (!data || data?.items?.length === 0) {
        setGeoSuccess(false);
      }
    },
    [dispatch],
  );

  // Once serversideprops are available, it will dispatch setHousingsOffers and display these listings on homepage.
  useEffect(() => {
    if (propertyAllListRent?.items)
      dispatch(setHousingOffers(propertyAllListRent));
  }, [propertyAllListRent, dispatch]);

  // If localstorage has this item, it will fetch it, but currently the item has no use as far as i know.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const location = localStorage.getItem('location');
    if (location === 'false') {
      setShowToast(false);
    }
    if (location === null) {
      setShowToast(true);
    }
    if (location === 'true') {
      setShowToast(false);
      const coords = localStorage.getItem('coords');
      const obj = JSON.parse(coords);
      return search(obj.lat, obj.lng);
    }
  }, [search]);
  // Ask user for geolocation access. Location has no further use throughout the app so far.
  const handleChangeGeoLocation = useCallback(() => {
    setShowToast(!isShowToast);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setGeoSuccess(true);
        localStorage.setItem('location', 'true');
        const lat = position.coords.longitude;
        const lng = position.coords.latitude;
        localStorage.setItem('coords', `{"lat": ${lat}, "lng": ${lng}}`);
        return search(lat, lng);
      },
      () => {
        setGeoSuccess(false);
        localStorage.setItem('location', 'false');
      },
    );
    isGeolocationAsked.current = true;
  }, [isShowToast, search]);

  // Will dispatch autosuggest once 4 characters are typed in the input.
  useEffect(() => {
    if (place.length > 3 && !isChoosePlace) {
      dispatch(fetchAutoSuggestText(place.toLowerCase()))
        .then((res) => {
          if (res?.data?.data?.length && place.length !== 0) {
            setSuggestPlaces(res.data.data);
          }
        })
        .catch((e) => console.log(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, place]);

  // Switch between immobilien & mietspiegel on Homescreen input.
  const handleChangeActiveButton = useCallback((tabName) => {
    if (tabName === IMMOBILIE) {
      setIsActive(IMMOBILIE);
      setRealEstateOrMarket(IMMOBILIE);
      setChoosePlace(false);
    }
    if (tabName === MARKTMIETSPIEGEL) {
      setIsActive(MARKTMIETSPIEGEL);
      setRealEstateOrMarket(MARKTMIETSPIEGEL);
      setChoosePlace(false);
    }
  }, []);

  // Will only fetch data, if the typed in Cityname is complete. Ber/Berl/Berli for example will default
  // to "Deutschland". If guarantees the default "Deutschland" behaviour.
  const handlePlaceChanged = useCallback(
    (e) => {
      setPlace(e.target.value);
      if (place.length < 3) {
        setChoosePlace(false);
        setSuggestPlaces([]);
      }
    },
    [place],
  );

  // Autosuggest => If you click an item from autosuggest, it will redirect you depending on your search.
  // buy/rent house/apartment mietspiegel/immobilien => All features available on homescreen input.
  const redirect = useCallback(() => {
    // eslint-disable-next-line max-len
    const searchInputValue = autoSuggestPlaces.find(
      (item) => item.title.toLowerCase() === place.toLowerCase(),
    );
    dispatch(clearOffers());
    if (isChoosePlace && realEstateOrMarket === IMMOBILIE) {
      return router.push(
        `/immobilien/${selectedChoosePlace.urlpath}/${housing}-${rentOrBuy}`,
      );
    }
    if (!isChoosePlace && realEstateOrMarket === IMMOBILIE) {
      if (searchInputValue) {
        return router.push(
          `/immobilien/${searchInputValue.urlpath}/${housing}-${rentOrBuy}`,
        );
      }
      return router.push(`/immobilien/deutschland/${housing}-${rentOrBuy}`);
    }
    if (isChoosePlace && realEstateOrMarket === MARKTMIETSPIEGEL) {
      return router.push(`/mietspiegel/${selectedChoosePlace.urlpath}`);
    }
    if (!isChoosePlace && realEstateOrMarket === MARKTMIETSPIEGEL) {
      if (searchInputValue) {
        return router.push(`/mietspiegel/${searchInputValue.urlpath}`);
      }
      return router.push('/mietspiegel');
    }
    return router.push(`/immobilien/${place}/${housing}-${rentOrBuy}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoSuggestPlaces,
    selectedChoosePlace,
    isChoosePlace,
    realEstateOrMarket,
    router,
    place,
    housing,
    rentOrBuy,
  ]);

  // Renders autosuggest with items => Also has background color depending on active state and mobile/desktop.
  const renderAutoPlaces = useCallback(
    (autoPlace, index) => {
      const choose = () => {
        setChoosePlace(true);
        setPlace(autoPlace.title);
        setSelectedChoosePlace(autoPlace);
        setSuggestPlaces([]);
      };
      return (
        <p
          key={index}
          role="presentation"
          onClick={choose}
          style={
            selectItem === index && screenWidth >= 550
              ? { backgroundColor: 'lightblue' }
              : { backgroundColor: 'white' }
          }
        >
          {`${autoPlace.title} `}({autoPlace.urlpath})
        </p>
      );
    },
    [selectItem, screenWidth],
  );

  // Resetting setSelectItem whenever autoSuggest updates. Used for arrow-nagivation, so you always have
  // the first item selected when a new suggestion appears.
  useEffect(() => {
    setSelectItem(0);
  }, [autoSuggestPlaces]);

  // Used for arrow/key navigation. The highlighted/active item will be loaded on keypress "enter".
  const renderPlacesOnEnter = useCallback((autoPlace) => {
    setChoosePlace(true);
    setPlace(autoPlace.title);
    setSelectedChoosePlace(autoPlace);
    setSuggestPlaces([]);
  }, []);

  // Navigation via keys. Limits the array between index 0 and maximum length of suggested items.
  const navigateAutoPlaces = useCallback(
    (e) => {
      if (
        (e.key === 'ArrowDown' || e.key === 'Down') &&
        autoSuggestPlaces.length &&
        selectItem < autoSuggestPlaces.length - 1
      ) {
        setSelectItem((prev) => prev + 1);
      } else if (
        (e.key === 'ArrowUp' || e.key === 'Up') &&
        autoSuggestPlaces.length &&
        selectItem > 0
      ) {
        setSelectItem((prev) => prev - 1);
      } else if (e.key === 'Enter' && autoSuggestPlaces.length > 0) {
        renderPlacesOnEnter(autoSuggestPlaces[selectItem]);
      }
    },
    [autoSuggestPlaces, selectItem, renderPlacesOnEnter],
  );

  // Redirect after you clicked an item or via "enter".
  useEffect(() => {
    if (isChoosePlace && selectedChoosePlace) {
      redirect();
    }
  }, [isChoosePlace, selectedChoosePlace, redirect]);

  const changeHouseOrApartment = useCallback(
    (data) => {
      setHousing(data);
      dispatch(setSearchData({ selectedHousing: data }));
    },
    [dispatch],
  );

  const changeRentOrBuy = useCallback(
    (data) => {
      setRentOrBuy(data.toLowerCase());
      dispatch(setSearchData({ selectedBuyOrRent: data.toLowerCase() }));
    },
    [dispatch],
  );

  const toggleShowToast = useCallback(
    () => setShowToast(!isShowToast),
    [isShowToast],
  );

  // If no item is shown in the autosuggest, the array is empty and will redirect to "Deutschland".
  // eslint-disable-next-line max-len
  const handleKeyPress = useCallback(
    (e) => e.key === 'Enter' && redirect() && setSuggestPlaces([]),
    [redirect],
  );

  const searchBoxProps = {
    isActive,
    place,
    handleChangeActiveButton,
    changeHouseOrApartment,
    changeRentOrBuy,
    handlePlaceChanged,
    handleKeyPress,
    navigateAutoPlaces,
    redirect,
    autoSuggestPlaces,
    renderAutoPlaces,
  };

  return (
    <div>
      <CustomToast
        toggleShowToast={toggleShowToast}
        isShowToast={isShowToast}
        handleChangeGeoLocation={handleChangeGeoLocation}
      />
      <SearchBoxSection props={searchBoxProps} />
      <AboutText />
      <CityMietspiegelSection options={options} />
      <FindImmoSection />
      <OfferCarouselSection
        isGeoSuccess={isGeoSuccess}
        geoUserData={geoUserData}
        resultData={resultData}
        parentData={parentData}
      />
      <RentBuyCityListSection options={options} />
      <Footer />
    </div>
  );
};

Home.propTypes = {
  resultData: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};

export default Home;
