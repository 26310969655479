import classNames from 'classnames';
import styles from '../Home.module.scss';
import { largeIndent, smallIndent } from '../../../utils/constants';

const AboutText = () => (
  <section
    className="container"
    style={{
      paddingBottom: `${largeIndent + 2.9985}rem`,
      paddingTop: `${largeIndent - 0.40625}rem`,
    }}
  >
    <div style={{ marginBottom: `${smallIndent - 0.46875}rem` }}>
      <h2
        className={classNames(
          'mb-0 g-color-gray-dark-v3 h2 g-font-weight-700 text-uppercase text-right',
          styles.myStyle,
        )}
      >
        Immobilien-Angebote vergleichen
      </h2>
      <h2
        className={classNames(
          'mb-0 g-color-gray-dark-v3 h2 g-font-weight-700 text-uppercase text-right',
        )}
      >
        Portalübergreifend. Präzise. Kostenlos.
      </h2>
    </div>

    <p className={classNames('g-color-gray-dark-v3', styles.paragraph)}>
      Sie suchen eine Immobilie oder wollen eine verkaufen? Mit Wohnpreis finden
      Sie die Immobillienangebote in Ihrer Stadt - von allen gängigen Portalen.
      Filter helfen Ihnen, das Umfeld Ihres Objekts präzise nach Stadtteilen
      oder Adresse zu prüfen.
    </p>
  </section>
);

export default AboutText;
