import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  largeIndent,
  smallIndent,
  SLIDER_SETTINGS,
} from '../../../utils/constants';
import MultipleItems from '../../common/Slider';

const OfferCarouselSection = ({
  isGeoSuccess,
  geoUserData,
  resultData,
  parentData,
}) => {
  const dataSlider = useMemo(() => {
    if (isGeoSuccess) {
      return {
        data: geoUserData.items,
        title: geoUserData?.items?.[0]?.city || '',
      };
    }
    return {
      data: resultData.propertyAllListRent.items,
      title: parentData?.items?.[0]?.title || '',
    };
  }, [parentData, isGeoSuccess, geoUserData, resultData]);

  return (
    <section
      className="container"
      style={{ paddingBottom: `${largeIndent}rem` }}
    >
      <div className="mb-5">
        <h2 className="h2 g-color-gray-dark-v3 g-font-weight-700 text-uppercase g-mb-0">
          Angebote im Überblick
        </h2>
        <span className="h3 g-color-gray-dark-v3 text-uppercase">
          Immobilien in Toplagen
        </span>
        <div style={{ marginTop: `${smallIndent}rem` }}>
          <MultipleItems
            isShowIndicators
            title={dataSlider.title}
            data={dataSlider.data}
            horizontal
            settings={SLIDER_SETTINGS}
          />
        </div>
      </div>
    </section>
  );
};

OfferCarouselSection.propTypes = {
  isGeoSuccess: PropTypes.bool,
  geoUserData: PropTypes.object,
  resultData: PropTypes.object,
  parentData: PropTypes.object,
};

OfferCarouselSection.defaultProps = {
  isGeoSuccess: false,
  geoUserData: {},
  resultData: {},
  parentData: {},
};
export default OfferCarouselSection;
