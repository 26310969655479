import { largeIndent, smallIndent } from '../../../utils/constants';

const Footer = () => (
  <>
    <section
      className="container"
      style={{ paddingBottom: `${largeIndent - 0.5625}rem` }}
    >
      <div>
        <h2 className="h2 g-color-gray-dark-v3 g-font-weight-700 text-uppercase g-mb-0">
          Das IIB Institut
        </h2>
        <span className="h3 g-color-gray-dark-v3 text-uppercase">
          Unabhängige Marktforschung
        </span>
        <p
          className="g-color-gray-dark-v3 mb-0"
          style={{ marginTop: `${smallIndent - 0.46875}rem` }}
        >
          Das IIB Institut ist ein familiengeführtes Unternehmen in Schwetzingen
          (Baden-Württemberg), das auf rund 30 Jahre Erfahrung in der Analyse
          von Immobilienmärkten und Wohnlagen zurückblickt. Unabhängig und
          selbst finanziert ist es uns ein Anliegen, für Transparenz auf dem
          Immobilienmarkt zu sorgen. Die iib Wohnlagenkarte basiert auf einem
          eigens vom IIB Institut entwickelten Rechenmodell.
        </p>
      </div>
    </section>
    <section
      className="container"
      style={{ paddingBottom: `${largeIndent - 0.3125}rem` }}
    >
      <div>
        <h2 className="h2 g-color-gray-dark-v3 g-font-weight-700 text-uppercase g-mb-0">
          datenschutz
        </h2>
        <span className="h3 g-color-gray-dark-v3 text-uppercase">
          Umgang mit ihren Eingaben
        </span>
        <p
          className="g-color-gray-dark-v3 mb-0"
          style={{ marginTop: `${smallIndent - 0.46875}rem` }}
        >
          Datenschutz ist uns wichtig. Wir speichern und erfassen persönliche
          Daten nur mit Ihrem Einverständnis. Wir speichern keine persönlichen
          Daten auf Vorrat und verkaufen sie nicht an Dritte. Unsere
          Marktforschung konzentriert sich auf Wohnlagen und Wohnpreise, die wir
          Ihnen zum Beispiel auf wohnpreis.de zur Verfügung stellen.
        </p>
      </div>
    </section>
  </>
);

export default Footer;
