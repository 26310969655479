import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-bootstrap/Toast';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const CustomToast = ({
  isShowToast,
  toggleShowToast,
  handleChangeGeoLocation,
}) => (
  <div className={styles.fixed}>
    <Toast show={isShowToast} onClose={toggleShowToast}>
      <Toast.Header closeButton={false}>
        <strong className="mr-2">
          Diese Seite möchte Ihren Standort verwenden
        </strong>
        <FontAwesomeIcon
          onClick={toggleShowToast}
          icon={faTimesCircle}
          style={{
            zIndex: '3',
            width: '13.55px',
            height: '13.55px',
            cursor: 'pointer',
          }}
        />
      </Toast.Header>
      <Toast.Body>
        <div className={styles.toastBody}>
          <button
            type="button"
            onClick={handleChangeGeoLocation}
            className={styles.btn}
          >
            Ok
          </button>
        </div>
      </Toast.Body>
    </Toast>
  </div>
);

CustomToast.propTypes = {
  isShowToast: PropTypes.bool,
  toggleShowToast: PropTypes.func,
  handleChangeGeoLocation: PropTypes.func,
};
CustomToast.defaultProps = {
  isShowToast: false,
  toggleShowToast: () => {},
  handleChangeGeoLocation: () => {},
};
export default CustomToast;
