import classNames from 'classnames';
import { Parallax } from 'react-parallax';
import PropTypes from 'prop-types';
import styles from '../../Home.module.scss';
import {
  SWITCH1_VALUES,
  SWITCH2_VALUES,
  MARKTMIETSPIEGEL,
  IMMOBILIE,
} from '../../../../utils/constants';
import SwitchGroup from '../../../common/SwitchGroup';
import SwitchTabs from './switchTabs';

const SearchBoxSection = ({ props }) => {
  const {
    isActive,
    place,
    handleChangeActiveButton,
    changeHouseOrApartment,
    changeRentOrBuy,
    handlePlaceChanged,
    handleKeyPress,
    navigateAutoPlaces,
    redirect,
    renderAutoPlaces,
    autoSuggestPlaces,
  } = props;

  return (
    <section className="position-relative">
      <div className={classNames('container', styles.startPageInfo)}>
        <h1>Was Wohnen wirklich kostet</h1>
        <span className="g-color-gray-dark-v3">
          Der neutrale Marktüberblick
        </span>
      </div>
      <Parallax
        style={{ height: '700px' }}
        bgImage="/assets/img/startPage/startPage1920X900.webp"
        bgImageSrcSet="/assets/img/startPage/startPage320X320.webp 320w,
            /assets/img/startPage/startPage480X480.webp 480w,
            /assets/img/startPage/startPage800X900.webp 800w,
            /assets/img/startPage/startPage960X900.webp 900w,
            /assets/img/startPage/startPage1920X900.webp 1920w
            "
        strength={600}
      />
      <div
        className={classNames(
          'container u-bg-overlay__inner text-center',
          styles.blockSearch,
        )}
      >
        <div className="shortcode-html">
          <SwitchTabs
            handleChangeActiveButton={handleChangeActiveButton}
            isActive={isActive}
          />

          <div className={styles.navbg}>
            <div className={styles.searchBlock}>
              <div>
                {isActive === MARKTMIETSPIEGEL && (
                  <div className={styles.ask} data-cy="miet-text">
                    <p>In welcher Stadt suchen Sie?</p>
                  </div>
                )}
                {isActive === IMMOBILIE && (
                  <div
                    className={classNames(
                      'd-flex g-width-100x align-items-end g-pl-60 g-pt-60',
                      styles.switcherBlockHomePage,
                    )}
                    data-cy="immo-text"
                  >
                    <SwitchGroup
                      homePage
                      values={SWITCH1_VALUES}
                      onChange={changeHouseOrApartment}
                      defaultValue="wohnung"
                    />
                    <SwitchGroup
                      homePage
                      values={SWITCH2_VALUES}
                      onChange={changeRentOrBuy}
                      defaultValue="Mieten"
                    />
                  </div>
                )}
                <div className={styles.searchInput} style={{ height: '37px' }}>
                  <div className="input-group">
                    <input
                      style={{ height: '100%' }}
                      value={place}
                      onChange={handlePlaceChanged}
                      type="text"
                      className="form-control g-font-size-20 border-1"
                      onKeyPress={handleKeyPress}
                      onKeyDown={(e) => navigateAutoPlaces(e)}
                      data-cy="autosuggest-input"
                    />
                    <div className="input-group-append">
                      <button
                        className={styles.btn}
                        type="button"
                        onClick={redirect}
                        data-cy="autosuggest-button"
                      >
                        Suchen
                      </button>
                    </div>
                  </div>
                </div>
                {autoSuggestPlaces?.length ? (
                  <div className={styles.chooseBlock}>
                    <div data-cy="autosuggest-results">
                      {autoSuggestPlaces.map(renderAutoPlaces)}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SearchBoxSection.propTypes = {
  props: PropTypes.object,
  isActive: PropTypes.string,
  place: PropTypes.object,
  handleChangeActiveButton: PropTypes.func,
  changeHouseOrApartment: PropTypes.func,
  changeRentOrBuy: PropTypes.func,
  handlePlaceChanged: PropTypes.func,
  handleKeyPress: PropTypes.func,
  navigateAutoPlaces: PropTypes.func,
  redirect: PropTypes.func,
  renderAutoPlaces: PropTypes.func,
  autoSuggestPlaces: PropTypes.object,
};

SearchBoxSection.defaultProps = {
  props: {},
  isActive: '',
  place: {},
  handleChangeActiveButton: () => '',
  changeHouseOrApartment: () => '',
  changeRentOrBuy: () => '',
  handlePlaceChanged: () => '',
  handleKeyPress: () => '',
  navigateAutoPlaces: () => '',
  redirect: () => '',
  renderAutoPlaces: () => '',
  autoSuggestPlaces: {},
};

export default SearchBoxSection;
