import { useCallback } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBuilding } from '@fortawesome/free-solid-svg-icons';
import styles from '../Home.module.scss';

import { largeIndent, smallIndent } from '../../../utils/constants';

const RentBuyCityListSection = ({ options }) => {
  const renderHousingOffers = useCallback(
    (option, idx, housingOffers) => (
      <p
        key={idx}
        className="g-color-gray-dark-v3 g-cursor-pointer g-py-10 g-px-10 mb-0"
      >
        <Link
          className="g-cursor-pointer g-py-10"
          href={`/immobilien/${encodeURIComponent(
            option.url,
          )}/${encodeURIComponent(housingOffers)}`}
          passHref
        >
          <a className="g-color-gray-dark-v3 g-cursor-pointer">
            {idx + 1}. {option.name}
          </a>
        </Link>
      </p>
    ),
    [],
  );

  return (
    <section
      className="container"
      style={{ paddingBottom: `${largeIndent}rem` }}
    >
      <div style={{ paddingBottom: `${smallIndent - 0.3125}rem` }}>
        <h2 className="h2 g-color-gray-dark-v3 g-font-weight-700 text-uppercase g-mb-0">
          Städte mit den meisten Angeboten
        </h2>
        <span className="h3 g-color-gray-dark-v3 text-uppercase">
          Die beliebtesten Städte Deutschlands
        </span>
        <p
          className="g-color-gray-dark-v3 text-left g-mb-0"
          style={{ paddingTop: `${smallIndent}rem` }}
        >
          Welche Städte haben momentan die meisten Immobilienangebote auf dem
          Markt? Klicken Sie auf eine beliebige <br />
          Stadt, um in den aktuellsten Immobilienseraten zu stöbern.
        </p>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className={classNames('col-sm', styles.immobilienOffersBox)}>
            <div className={styles.immobilienOffersIcon}>
              <FontAwesomeIcon
                icon={faBuilding}
                style={{ width: '100px', height: '100px' }}
              />
              <h3 className="h4 g-color-gray-dark-v3 text-uppercase font-weight-bold g-mt-20">
                Wohnung Kauf
              </h3>
            </div>
            <article className="g-pos-rel g-pt-5">
              <div className="g-color-black">
                {options.map((option, idx) =>
                  renderHousingOffers(option, idx, 'wohnung-kaufen'),
                )}
              </div>
            </article>
          </div>

          <div className={classNames('col-sm', styles.immobilienOffersBox)}>
            <div className={styles.immobilienOffersIcon}>
              <FontAwesomeIcon
                icon={faBuilding}
                style={{ width: '100px', height: '100px' }}
              />
              <h3 className="h4 g-color-gray-dark-v3 text-uppercase font-weight-bold g-mt-20">
                Wohnung Miete
              </h3>
            </div>
            <article className="g-pos-rel g-pt-5">
              <div className="g-color-black">
                {options.map((option, idx) =>
                  renderHousingOffers(option, idx, 'wohnung-mieten'),
                )}
              </div>
            </article>
          </div>

          <div className={classNames('col-sm', styles.immobilienOffersBox)}>
            <div className={styles.immobilienOffersIcon}>
              <FontAwesomeIcon
                icon={faHome}
                style={{ width: '100px', height: '100px' }}
              />
              <h3 className="h4 g-color-gray-dark-v3 text-uppercase font-weight-bold g-mt-20">
                Haus Kauf
              </h3>
            </div>
            <article className="g-pos-rel g-pt-5">
              <div className="g-color-black">
                {options.map((option, idx) =>
                  renderHousingOffers(option, idx, 'haus-kaufen'),
                )}
              </div>
            </article>
          </div>

          <div className={classNames('col-sm', styles.immobilienOffersBox)}>
            <div className={styles.immobilienOffersIcon}>
              <FontAwesomeIcon
                icon={faHome}
                style={{ width: '100px', height: '100px' }}
              />
              <h3 className="h4 g-color-gray-dark-v3 text-uppercase font-weight-bold g-mt-20">
                Haus Miete
              </h3>
            </div>
            <article className="g-pos-rel g-pt-5">
              <div className="g-color-black">
                {options.map((option, idx) =>
                  renderHousingOffers(option, idx, 'haus-mieten'),
                )}
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

RentBuyCityListSection.propTypes = {
  options: PropTypes.array,
};

RentBuyCityListSection.defaultProps = {
  options: [],
};
export default RentBuyCityListSection;
