import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from '../../Home.module.scss';

import { IMMOBILIE, MARKTMIETSPIEGEL } from '../../../../utils/constants';

const SwitchTabs = ({ isActive, handleChangeActiveButton }) => (
  <>
    <ul
      className={classNames(
        'nav justify-content-end u-nav-primary',
        styles.myBtn,
      )}
    >
      <li
        className={classNames('nav-item', styles.tab, {
          [styles.active]: isActive === IMMOBILIE,
        })}
      >
        <p
          role="presentation"
          onClick={() => handleChangeActiveButton(IMMOBILIE)}
          className={classNames('nav-link', {
            [styles.activeText]: isActive === IMMOBILIE,
          })}
          data-cy="immo-switch"
        >
          Immobilien
        </p>
      </li>
      <li
        className={classNames('nav-item', styles.tab, {
          [styles.active]: isActive === MARKTMIETSPIEGEL,
        })}
      >
        <p
          style={{ padding: '7px 8px 7px 8px' }}
          role="presentation"
          onClick={() => handleChangeActiveButton(MARKTMIETSPIEGEL)}
          className={classNames('nav-link', {
            [styles.activeText]: isActive === MARKTMIETSPIEGEL,
          })}
          data-cy="miet-switch"
        >
          Marktmietspiegel
        </p>
      </li>
    </ul>
  </>
);

SwitchTabs.propTypes = {
  isActive: PropTypes.string,
  handleChangeActiveButton: PropTypes.func,
};
SwitchTabs.defaultProps = {
  isActive: '',
  handleChangeActiveButton: () => '',
};

export default SwitchTabs;
