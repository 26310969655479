import Link from 'next/link';
import classNames from 'classnames';
import styles from '../Home.module.scss';

import { largeIndent, smallIndent } from '../../../utils/constants';

const FindImmoSection = () => (
  <section className="container" style={{ paddingBottom: `${largeIndent}rem` }}>
    <div style={{ marginBottom: `${smallIndent - 0.59375}rem` }}>
      <h2 className={classNames(styles.heading, 'mb-0')}>Immobilien</h2>
    </div>
    <div className={classNames('d-flex flex-wrap', styles.immobnilienBox)}>
      <img
        className={classNames(
          'col-md-6 g-pl-0',
          styles.startPageImmobilienImage,
        )}
        src="/assets/img/startPageImmobilien/startPageImmobilien.webp"
        srcSet="/assets/img/startPageImmobilien/startPageImmobilien480X320.webp 480w"
        sizes="(max-width 480px) 480px"
        loading="eager"
        alt="immobilien"
      />
      <div className="col-md-6 g-pb-20">
        <h2
          className={classNames(
            'h2 g-color-gray-dark-v3 g-font-weight-700 text-uppercase g-mb-0',
            styles.myStyle,
          )}
        >
          Immobilie finden
        </h2>
        <span className="h3 g-color-gray-dark-v3 text-uppercase">
          Ihr Einblick in den tagesaktuellen Markt
        </span>
        <p
          className="g-color-gray-dark-v3 text-left g-mb-0"
          style={{ marginTop: `${smallIndent - 0.90625}rem` }}
        >
          Mithilfe der Wohnlagenkarte erhalten Sie einen kompakten
          Marktüberblick. Wir bieten Ihnen die aktuellen Angebote aller gängigen
          Internetportale – ohne Doppelungen und übersichtlich dargestellt.
        </p>
        <div
          className="d-flex"
          style={{ marginTop: `${smallIndent - 0.15625}rem` }}
        >
          <Link href="/immobilien">
            <span className={styles.buttonLink} data-cy="suche-starten">
              Suche starten
            </span>
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default FindImmoSection;
