import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from '../Switch/index.module.scss';
import { clearSelectedFilters } from '../../../store/market/actions';

const SwitchGroup = ({
  values,
  onChange,
  defaultValue,
  triggerOnDefault,
  isSearchBox,
  isRentOrBuy,
  homePage,
}) => {
  const [activeElement, setActiveElement] = useState(defaultValue);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveElement(defaultValue);
  }, [defaultValue]);

  const handleItemChange = useCallback(
    (item) => {
      if (activeElement !== (item.value || item)) {
        setActiveElement(item.value || item);
        onChange(item);
      }
    },
    [activeElement, onChange],
  );

  const renderItem = useCallback(
    (item, idx) => (
      <div
        key={idx.toString()}
        onClick={() => {
          dispatch(clearSelectedFilters());
          handleItemChange(item.value || item);
        }}
        role="presentation"
        data-cy="switcher"
        className={classNames(
          {
            [styles.active]: activeElement === (item.value || item),
          },
          item.className,
        )}
      >
        {typeof item !== 'object' ? item.value : null}
        <span
          className={classNames({ [styles.span]: isSearchBox && isRentOrBuy })}
        >
          {item.showValue ? item.value : null}
        </span>
        {item.icon &&
          (typeof item.icon === 'object' ? (
            item.icon
          ) : (
            <img src={item.icon} alt="icon" style={item.style} />
          ))}
      </div>
    ),

    [activeElement, isSearchBox, isRentOrBuy, dispatch, handleItemChange],
  );

  useEffect(() => {
    if (triggerOnDefault) onChange(defaultValue);
  }, [triggerOnDefault, onChange, defaultValue]);

  return (
    <div className={styles.blockSwitch}>
      <div
        className={classNames(styles.switcher, {
          [styles.switcherFromSearchBox]: isSearchBox,
          [styles.switcherHomePage]: homePage,
        })}
      >
        {values.map(renderItem)}
      </div>
    </div>
  );
};

SwitchGroup.propTypes = {
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      }),
    ),
  ]),
  isRentOrBuy: PropTypes.bool,
  isSearchBox: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  triggerOnDefault: PropTypes.bool,
  homePage: PropTypes.bool,
};

SwitchGroup.defaultProps = {
  isRentOrBuy: false,
  isSearchBox: false,
  defaultValue: '',
  onChange: () => null,
  values: [],
  triggerOnDefault: false,
  homePage: false,
};

export default SwitchGroup;
