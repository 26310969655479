import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

// This hook is responsible for updating the current screenwidth. Since the dependency array is empty,
// the hook would call on each pixel change. Debounce throttles this function and only applies the update after 100ms.

const useResize = () => {
  const [screenWidth, setScreenWidth] = useState();
  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    let isComponentLoaded = true;
    const handleResize = () => {
      if (isComponentLoaded) setScreenWidth(window.innerWidth);
    };

    const debounceHandleResize = debounce(handleResize, 100);
    window.addEventListener('resize', debounceHandleResize);
    return () => {
      window.removeEventListener('resize', debounceHandleResize);
      isComponentLoaded = false;
    };
  }, []);

  return screenWidth;
};

export default useResize;
