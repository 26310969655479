import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import classNames from 'classnames';
import styles from '../Home.module.scss';

import { largeIndent, smallIndent } from '../../../utils/constants';

const CityMietspiegelSection = ({ options }) => {
  const [chunks, setChunks] = useState([]);

  // Desktop: Will split the cityarray with 16 items in chunks. Result will be [6,6,4] which will appear as
  // lists next to eachother. Mobile: List appears without splitting.
  useEffect(() => {
    const chunkLen = 6;
    const result = [...Array(Math.ceil(options.length / chunkLen))].map(
      (n, i) => options.slice(i * chunkLen, (i + 1) * chunkLen),
    );
    setChunks(result);
  }, [options]);

  const renderCityLink = useCallback(
    (option, idx) => (
      <Link
        className="g-cursor-pointer g-py-10"
        key={idx}
        href={`/mietspiegel/${encodeURIComponent(option.url)}`}
        passHref
      >
        <a
          className={classNames(
            'g-cursor-pointer g-py-10 g-color-gray-dark-v4 d-flex justify-content-between',
            styles.activeLink,
          )}
          data-cy="city-link"
        >
          {option.name}{' '}
          <span className="m-0" data-cy="city-data">
            {option['0']?.angebotspreise_monat_fair
              ? `€ ${
                  String(option['0'].angebotspreise_monat_fair).length > 1
                    ? parseFloat(option['0'].angebotspreise_monat_fair).toFixed(
                        2,
                      )
                    : parseFloat(option['0'].angebotspreise_monat_fair).toFixed(
                        0,
                      )
                }
          `
              : '0'}
          </span>
        </a>
      </Link>
    ),
    [],
  );

  return (
    <section
      className="container"
      style={{ paddingBottom: `${largeIndent + 2.84225}rem` }}
    >
      <div
        className="d-flex flex-wrap"
        style={{ marginBottom: `${smallIndent}rem` }}
      >
        <div className="col-md-6 g-pb-20 g-pl-0">
          <div style={{ marginBottom: `${smallIndent - 0.71875}rem` }}>
            <h2 className={classNames(styles.heading, 'mb-0')}>
              Markt-Mietspiegel
            </h2>
          </div>
          <h2
            className={classNames(
              'h2 g-color-gray-dark-v3 g-font-weight-700 text-uppercase g-mb-0',
              styles.myStyle,
            )}
          >
            finde jetzt den <br /> Markt-Mietspiegel deiner Stadt
          </h2>
          <span className="h3 g-color-gray-dark-v3 text-uppercase">
            Aktuelle Immobilienpreise
          </span>
          <p
            className="g-color-gray-dark-v3 text-left g-mb-0"
            style={{ marginTop: `${smallIndent - 0.90625}rem` }}
          >
            Hier können Sie sich über die aktuellen Preise auf dem Mietmarkt
            Ihrer Wahl informieren – egal, ob als Mieter, Vermieter oder im Fall
            eines Mietstreits. Die Werte entsprechen dem derzeitigen
            Durchschnitt.
          </p>
          <div
            className="d-flex"
            style={{ marginTop: `${smallIndent - 0.15625}rem` }}
          >
            <Link href="/mietspiegel">
              <span data-cy="mietspiegel-link" className={styles.buttonLink}>
                Markt-Mietspiegel
              </span>
            </Link>
          </div>
        </div>
        <img
          className={classNames(
            'col-md-6 g-pr-0',
            styles.startPageMietspiegelImage,
          )}
          src="/assets/img/startPageMietspiegel/startPageMietspiegelDefault.webp"
          srcSet="/assets/img/startPageMietspiegel/startPageMietspiegel480x320.webp 480w"
          sizes="(max-width 480px) 480px"
          loading="eager"
          alt="graphMietspiegel"
        />
      </div>
      <div className={classNames('g-pr-0', styles.cityBox)}>
        {chunks?.map((chunk) => (
          <div key={Math.random()} className="col-md-4">
            {chunk.map(renderCityLink)}
          </div>
        ))}
      </div>
    </section>
  );
};

CityMietspiegelSection.propTypes = {
  options: PropTypes.array,
};

CityMietspiegelSection.defaultProps = {
  options: [],
};

export default CityMietspiegelSection;
